import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the default React Quill styling
import { Box, Typography } from '@mui/material'; // MUI components

interface RichTextEditorProps {
  value: string;
  onChange: (content: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {
  return (
    <Box sx={{ margin: '20px 0' }}>

      {/* React Quill Editor */}
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder="Write something amazing..."
        style={{ background: 'white', borderRadius: '5px', minHeight: '300px' }}
        theme="snow"
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'], // Toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'], // Insert image, links
            ['clean'], // Remove formatting
          ],
        }}
      />

      {/* Styling the editor content */}
      <style>
        {`
          .ql-editor {
            color: black; /* Set text color to black */
          }
        `}
      </style>
    </Box>
  );
};

export default RichTextEditor;
