import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { auth, db } from '../firebaseConfig';
import { Container, TextField, Button, Typography, Box, ToggleButton, ToggleButtonGroup, Grid, CircularProgress, Grid2 } from '@mui/material';

const AuthForm = () => {
  const [isSignUp, setIsSignUp] = useState(false); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [country, setCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [resetMessage, setResetMessage] = useState('');

  const navigate = useNavigate(); 
  const location = useLocation(); 

  useEffect(() => {
    if (location.state && location.state.isSignUp) {
      setIsSignUp(true);
    }
  }, [location.state]);

  // Function to handle authentication
  const handleAuth = async () => {
    setError('');  
    setIsLoading(true);  
    
    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, {
          firstName,
          lastName,
          email: user.email,
          username,
          country,
          favoriteAssets: [],
          createdAt: new Date(),
        });
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }

      navigate('/'); 

    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setError('The email is already in use. Please log in or use another email.');
      } else if (err.code === 'auth/invalid-email') {
        setError('The email address is not valid.');
      } else if (err.code === 'auth/weak-password') {
        setError('The password is too weak. It should be at least 6 characters.');
      } else if (err.code === 'auth/user-not-found') {
        setError('User not found. Please check your email or sign up.');
      } else if (err.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }

    setIsLoading(false);
  };

  // Function to handle password reset
  const handlePasswordReset = async () => {
    setError('');
    setResetMessage('');
    
    if (!email) {
      setError('Please enter your email to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent! Please check your inbox.');
    } catch (err) {
      setError('Failed to send password reset email. Please try again.');
    }
  };

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) setIsSignUp(newMode === 'signup');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: 'white',
          p: 4,
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" gutterBottom>
          {isSignUp ? 'Sign Up' : 'Log In'}
        </Typography>

        <ToggleButtonGroup
          value={isSignUp ? 'signup' : 'login'}
          exclusive
          onChange={handleModeChange}
          sx={{ mb: 3 }}
        >
          <ToggleButton
            value="login"
            sx={{
              color: isSignUp ? 'black' : 'white',
              backgroundColor: isSignUp ? 'transparent' : 'purple',
              '&.Mui-selected': {
                color: 'white',
                backgroundColor: 'purple',
              },
              '&:hover': {
                backgroundColor: 'purple',
                color: 'white',
              },
            }}
          >
            Log In
          </ToggleButton>
          <ToggleButton
            value="signup"
            sx={{
              color: isSignUp ? 'white' : 'black',
              backgroundColor: isSignUp ? 'purple' : 'transparent',
              '&.Mui-selected': {
                color: 'white',
                backgroundColor: 'purple',
              },
              '&:hover': {
                backgroundColor: 'purple',
                color: 'white',
              },
            }}
          >
            Sign Up
          </ToggleButton>
        </ToggleButtonGroup>

        {error && <Typography sx={{ mb: 2, color: 'red' }}>{error}</Typography>}
        {resetMessage && <Typography sx={{ mb: 2, color: 'black' }}>{resetMessage}</Typography>}

        {isSignUp && (
          <Grid2 container spacing={2} sx={{ mb: 2 }}>
            <Grid2 xs={6}>
              <TextField
                fullWidth
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                InputProps={{
                  sx: { color: 'black' },
                }}
                InputLabelProps={{ style: { color: 'gray' } }}
              />
            </Grid2>
            <Grid2 xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                InputProps={{
                  sx: { color: 'black' },
                }}
                InputLabelProps={{ style: { color: 'gray' } }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                fullWidth
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  sx: { color: 'black' },
                }}
                InputLabelProps={{ style: { color: 'gray' } }}
              />
            </Grid2>
          </Grid2>
        )}
        
        <TextField
          fullWidth
          label="Email"
          type="email"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'gray' } }}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)} 
          InputProps={{ style: { color: 'black' } }}
          InputLabelProps={{ style: { color: 'gray' } }}
          sx={{ mb: 1 }}
        />

        {!isSignUp && (
          <Button
            onClick={handlePasswordReset}
            sx={{
              color: 'black',
              textTransform: 'none',
              fontSize: '0.875rem',
              display: 'block',
              textAlign: 'left',
              mt: 1,
              mb: 2,
            }}
          >
            Forgot Password?
          </Button>
        )}

        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleAuth}
          >
            {isSignUp ? 'Create Account' : 'Log In'}
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default AuthForm;
