import React from 'react';
import { Container, Typography, Box, Grid2, Avatar, Button } from '@mui/material';

const AboutPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8, textAlign: 'center' }} >
      {/* Header Section */}
      {/* <Box sx={{ textAlign: 'center', mb: 8 }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          About Us
        </Typography>
        <Typography variant="h6" color="white">
          Discover who we are, our mission, and the values that drive us.
        </Typography>
      </Box> */}

      {/* Mission Section */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
          Our Mission
        </Typography>
        <Typography  sx={{ lineHeight: 1.8, maxWidth: '70%', textAlign:"center", margin: '0 auto'}}>
          Our mission is to drive change by building products that solve real-world problems. We focus on providing innovative solutions 
          that enhance everyday life and business. With our expertise and dedication, we aim to exceed expectations in every project.
        </Typography>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="outlined" color="primary" size="large">
            Learn More
          </Button>
        </Box>
      </Box>

      {/* Team Section */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#fff', mb:8}}>
          Meet Our Team
        </Typography>
        <Grid2 container spacing={4} justifyContent="center">
          <Grid2 size={{ xs:12, sm: 6, md:4}} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              src={"https://randomuser.me/api/portraits/women/47.jpg"}
              sx={{ width: 120, height: 120, mb: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
              Jane Doe
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              CEO & Founder
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1, textAlign: 'center' }}>
              With over 15 years of industry experience, Jane is committed to leading the company with purpose and vision.
            </Typography>
          </Grid2>

          <Grid2 size={{ xs:12, sm: 6, md:4}} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              src={"https://randomuser.me/api/portraits/men/42.jpg"}
              sx={{ width: 120, height: 120, mb: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
              John Smith
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              CTO & Co-Founder
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1, textAlign: 'center' }}>
              John is a tech visionary with over a decade of experience. He is responsible for driving innovation and technology at our company.
            </Typography>
          </Grid2>
        </Grid2>
      </Box>

    </Container>
  );
};

export default AboutPage;
