import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { initialState, MoversData } from '../types/marketTable';

// Async thunk for fetching movers data
export const fetchMovers = createAsyncThunk('market/fetchMovers', async () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${baseUrl}/movers-stock`);
  const data: MoversData = await response.json();
  return data;
});


// Create the slice for the market table
const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMovers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMovers.fulfilled, (state, action: PayloadAction<MoversData>) => {
        state.movers = action.payload;
        state.loading = false;
      })
      .addCase(fetchMovers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error fetching data';
      });
  },
});

// Export the reducer to be used in the store
export default marketSlice.reducer;
