import React from "react";
import { Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import { Article } from "../types/news"; // Adjust the import path if necessary

interface ArticleItemProps {
  article: Article;
}

// Function to truncate the title if it's too long
const truncateTitle = (title: string, maxLength: number) => {
  if (title.length > maxLength) {
    return title.substring(0, maxLength - 3) + '...'; // Truncate and add ellipsis
  }
  return title;
};

const ArticleItem: React.FC<ArticleItemProps> = ({ article }) => {
  return (
    <a href={article.url} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
      <Card>
        <CardMedia
          component="img"
          height="140"
          image={article.banner_image}
          alt={article.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {truncateTitle(article.title, 50)} {/* Adjust the maxLength as needed */}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {article.summary}
          </Typography>
          <Button
            size="small"
            color="primary"
            href={article.url}
            target="_blank"
          >
            Read More
          </Button>
        </CardContent>
      </Card>
    </a>
  );
};

export default ArticleItem;
