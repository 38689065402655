import React from 'react';
import { Container, Typography, Box, Button, CssBaseline } from '@mui/material';

const ComingSoon: React.FC = () => {
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        color: '#fff',
      }}
    >
      <CssBaseline />
      <Box>
        <Typography variant="h3" sx={{ mb: 2, color: '#3f51b5' }}>
          Coming Soon
        </Typography>
        <Typography variant="h5" sx={{ mb: 4, color: '#fff' }}>
          We're working hard to launch our newest feature! Please check back soon
        </Typography>
        
      </Box>
    </Container>
  );
};

export default ComingSoon;
