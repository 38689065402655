import React, { useEffect, useState } from 'react';
import { Box, Grid2, Paper, Typography, Button, Alert } from '@mui/material';
import StockDrawer from './drawer';
import Logo from './logo'; 
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { cryptoAsset } from '../types/crypto';
import { IndividualStockCryptoQuote } from '../types/marketTable';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/index'; // Adjust the path as necessary

interface Stock {
  symbol: string;
  type: string;
  cryptoID?: string;
}

// Hardcoded stock data for non-logged-in users
const defaultStockList: Stock[] = [
  { symbol: 'AAPL', type: 'stock' },
  { symbol: 'GOOGL', type: 'stock' },
  { symbol: 'BTCUSD', type: 'crypto', cryptoID: "1" },
  { symbol: 'ETHUSD', type: 'crypto', cryptoID: "1027" },
  { symbol: 'EURUSD', type: 'forex' },
  { symbol: 'XAUUSD', type: 'commodities' },
  { symbol: 'DOW', type: 'indices' },
  { symbol: 'NVDA', type: 'stock' }
];

// Create a map of symbol to original asset details
const assetMap = defaultStockList.reduce((acc, asset) => {
  acc[asset.symbol] = { type: asset.type, cryptoID: asset.cryptoID };
  return acc;
}, {});

// Function to fetch data from the new API for crypto assets
const fetchCryptoDataFromNewAPI = async (cryptoID: string) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL; // Use the base URL from env variables
  const newApiUrl =`${baseUrl}/crypto-quote-by-id?id=${cryptoID}`;
  try {
    const response = await axios.get(newApiUrl);
    return response.data[cryptoID]; // Adjust based on the actual response structure
  } catch (error) {
    console.error('Failed to fetch data from new crypto API:', error);
    return null; // Return null if the fetch fails
  }
};

export const TopStocks = (profilePage?: boolean) => {
  const reload = useSelector((state: RootState) => state.topStocks.reload);

  const [topAssets, setTopAssets] = useState<cryptoAsset | IndividualStockCryptoQuote>([]);
  const [visibleAssets, setVisibleAssets] = useState<number>(8);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Stock | cryptoAsset | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasFavorites, setHasFavorites] = useState<boolean>(false);
  const user = auth.currentUser;

  useEffect(() => {
    const fetchAssets = async () => {
      let assetsList = defaultStockList;

      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            // console.log('User data favorite assets:', userData?.favoriteAssets);
            if (!(userData?.favoriteAssets && userData.favoriteAssets.length > 0)) {
              console.log('No favorite assets found');
              assetsList = defaultStockList;
            } else {
              setHasFavorites(true);
              assetsList = userData?.favoriteAssets;
            }
            console.log('User favorite assets:', assetsList);
          }
        } catch (error) {
          setErrorMessage('Failed to fetch user favorite assets, using default list');
        }
      }

      const symbols = assetsList.map(asset => asset.symbol).join(',');

      // Fetch data for all symbols
      const fetchedData = await fetchAssetsData(symbols);

      // Merge fetched data with original asset types and cryptoIDs
      const mergedAssets = await mergeAssetsWithDetails(fetchedData, assetsList);

      setTopAssets(mergedAssets);
    };

    fetchAssets();
  }, [user, reload]);

  // Merge logic for fetched assets and asset details
  const mergeAssetsWithDetails = async (fetchedData, originalAssets) => {
    const mergedAssets = await Promise.all(originalAssets.map(async (originalAsset) => {
      const apiData = fetchedData.find(asset => asset.symbol === originalAsset.symbol) || {};
      let mergedAsset = {
        ...originalAsset, // Keep original asset data
        ...apiData, // Merge API data
      };

      // If cryptoID exists, fetch additional data from the new API
      if (originalAsset.cryptoID) {
        const newApiData = await fetchCryptoDataFromNewAPI(originalAsset.cryptoID);
        if (newApiData) {
          mergedAsset = {
            ...mergedAsset,
            ...newApiData, // Merge additional data from the new API
          };
        }
      }
      return mergedAsset;
    }));

    console.log('Merged asset:', mergedAssets);

    return mergedAssets;
  };

  const fetchAssetsData = async (symbols: string) => {
    const baseUrl = 'https://financialmodelingprep.com/api/v3/quote';
    const apiKey = '9I5BcBDPrSAHGnszz7zCs0IXUhBPV7YS';

    try {
      const response = await axios.get(`${baseUrl}/${symbols}?apikey=${apiKey}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch assets:', error);
      return [];
    }
  };

  const handleCardClick = (asset) => {
    setSelectedAsset(asset);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSeeMore = () => {
    setVisibleAssets((prev) => prev + 8);
  };

  return (
    <Box>
      <Typography variant="h2" pb={8} sx={{ color: '#fff', textAlign: 'center' }}>
        Favorited Assets
      </Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMessage}
        </Alert>
      )}

{!hasFavorites && user && (
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Centers the alert horizontally
          mb: 3,
        }}
      >
        <Alert
          severity="info"
          sx={{
            width: '50%', // Set the width to half
            textAlign: 'center', // Optional: Center the text inside the alert
          }}
        >
          You haven't saved any favorites, so we are showing you some of our favorites 
        </Alert>
      </Box>
      
      )}

      {!user && (
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Centers the alert horizontally
          mb: 3,
        }}
      >
        <Alert
          severity="info"
          sx={{
            width: '50%', // Set the width to half
            textAlign: 'center', // Optional: Center the text inside the alert
          }}
        >
          Please login to save and view your favorite assets.
        </Alert>
      </Box>
      
      )}

      <Grid2 container spacing={3} justifyContent="center">
        {topAssets.slice(0, visibleAssets).map((asset, index) => (
          <Grid2 key={index} sx={{ xs: 12, sm: 6, md: 3 }}>
            <Paper
              elevation={3}
              sx={{
                padding: '1rem',
                backgroundColor: '#3e006f',
                borderRadius: '16px',
                color: '#fff',
                maxWidth: '250px',
                margin: 'auto',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                },
              }}
              onClick={() => handleCardClick(asset)}
            >
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ padding: '1rem' }}>
            <Grid2 sx={{ xs: 10 }} display="flex" alignItems="center">
                  <Box sx={{ width: '40px', height: '40px' }}>
                    <Logo symbol={asset.symbol} cryptoID={asset.cryptoID} market_type={undefined} logoUrl={undefined}/>
                  </Box>
                  <Typography variant="h6" sx={{ marginLeft: '2rem', fontWeight: 'bold', color: 'white' }}>
                    {asset.symbol}
                  </Typography>
                  <Typography variant="body1" sx={{ marginLeft: '1rem', color: 'white' }}>
  {asset.cryptoID ? 
    `$${(asset as cryptoAsset)?.quote?.USD?.price !== undefined && (asset as cryptoAsset)?.quote?.USD?.price !== null 
      ? (asset as cryptoAsset).quote.USD.price.toString().slice(0, 7) // Get first 6 characters
      : 'N/A'}` : 
    `$${asset.price !== undefined && asset.price !== null 
      ? asset.price.toString().slice(0, 7) 
      : 'N/A'}`
  }
</Typography>
{/* <br/>
<Typography variant="body1" sx={{ marginLeft: '1rem', color: 'white', display: 'flex', alignItems: 'center' }}>
  {asset.cryptoID ? 
    <>
      { (asset as cryptoAsset)?.quote?.USD?.percent_change_24h !== undefined && (asset as cryptoAsset)?.quote?.USD?.priceChange !== null ? (
        <>
          { (asset as cryptoAsset)!.quote!.USD!.percent_change_24h! > 0 ? (
            <ArrowUpward sx={{ color: 'green', marginRight: '4px' }} />
          ) : (
            <ArrowDownward sx={{ color: 'red', marginRight: '4px' }} />
          )}
          <span style={{ color: (asset as cryptoAsset)!.quote!.USD!.percent_change_24h! > 0 ? 'green' : 'red' }}>
            {(asset as cryptoAsset)!.quote!.USD!.percent_change_24h!.toString().slice(0, 7)}
          </span>
        </>
      ) : 'N/A' }
    </> : 
    <>
      { asset.changesPercentage !== undefined && asset.changesPercentage !== null ? (
        <>
          { asset.changesPercentage > 0 ? (
            <ArrowUpward sx={{ color: 'green', marginRight: '4px' }} />
          ) : (
            <ArrowDownward sx={{ color: 'red', marginRight: '4px' }} />
          )}
          <span style={{ color: asset.changesPercentage > 0 ? 'green' : 'red' }}>
            {asset.changesPercentage.toString().slice(0, 7)}
          </span>
        </>
      ) : 'N/A' }
    </>
  }
</Typography> */}

                  
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
        ))}
      </Grid2>

      {visibleAssets < topAssets.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button variant="contained" onClick={handleSeeMore}>
            See More
          </Button>
        </Box>
      )}

      <StockDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        symbol={selectedAsset?.symbol}
        cryptoID={selectedAsset && 'cryptoID' in selectedAsset ? selectedAsset.cryptoID : null}
      />
    </Box>
  );
};

export default TopStocks;