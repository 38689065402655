import { configureStore } from '@reduxjs/toolkit';
import marketReducer from './marketTable';
import mostActivesReducer from './mostActivesSlice';
import topStocksReducer from './topStockSlice'; // Ensure correct path to topStocksSlice

const store = configureStore({
  reducer: {
    market: marketReducer,
    mostActives: mostActivesReducer, 
    topStocks: topStocksReducer, // Use the correct reducer from topStocksSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
