import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { CryptoApiResponse } from '../types/crypto';

const CACHE_EXPIRATION_DAYS = 7;

const Logo = ({ symbol, market_type, cryptoID = null, logoUrl = null  }) => {
  const [imageUrl, setImageUrl] = useState(''); // State to hold the image URL
  const [imageError, setImageError] = useState(false); // Track if the image fails to load

  // console.log('logoUrl: ' + logoUrl, 'symbol: ' + symbol, 'market_type: ' + market_type, 'cryptoID: ' + cryptoID);

  // Check if logo is cached and less than 7 days old
  const getCachedLogo = (cryptoID) => {
    const cachedLogos = JSON.parse(localStorage.getItem('cryptoLogos') || '{}');
    const logoData = cachedLogos[cryptoID];

    if (logoData) {
      const now = new Date();
      const logoDate = new Date(logoData.timestamp);

      // Check if the cached logo is less than 7 days old
      const diffTime = Math.abs(now.getTime() - logoDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < CACHE_EXPIRATION_DAYS) {
        return logoData.url; // Return cached logo URL if valid
      }
    }
    return null; // Return null if no valid cached logo found
  };

  // Cache the fetched logo locally
  const cacheLogo = (cryptoID, url) => {
    const cachedLogos = JSON.parse(localStorage.getItem('cryptoLogos') || '{}');
    cachedLogos[cryptoID] = {
      url,
      timestamp: new Date(),
    };
    localStorage.setItem('cryptoLogos', JSON.stringify(cachedLogos));
  };

  const getImageUrl = async () => {
  
    if (!cryptoID) {
return `https://financialmodelingprep.com/image-stock/${symbol?.toUpperCase()}.png?apikey=qZkCwbYTgW5JG0VTz92UIbovoJCxIjmY`;
      // return `https://assets.parqet.com/logos/symbol/${symbol?.toUpperCase()}?format=jpg`;
    } else if (market_type === 'crypto' || cryptoID) {
      // Check for cached logo first
      const cachedLogo = getCachedLogo(cryptoID);
      if (cachedLogo) {
        return cachedLogo;
      }


      // Otherwise, fetch the logo
      return await getCryptoLogo(symbol, cryptoID);
    }
    return null;
  };

  const getCryptoLogo = async (symbol: string, cryptoID?: number) => {
    const cryptoSymbol = symbol.includes('/') ? symbol.split('/')[0]?.toUpperCase() : symbol?.toUpperCase();

    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      let url;

      // Constructing API URL based on whether cryptoID is present
      if (cryptoID) {
        url = `${baseUrl}/crypto-details?id=${cryptoID}`;
        const response = await axios.get<CryptoApiResponse>(url);
        const responseLogo = response.data.data[cryptoID]?.logo;
      // console.log('response logo: ' + responseLogo, cryptoID + ' ' + cryptoSymbol);

        if (responseLogo) {
          cacheLogo(cryptoID, responseLogo); // Cache the logo
          return responseLogo;
        }
        return null;
      }
    } catch (error) {
      console.error('Error fetching crypto logo:', error);
      return null; // Fallback value if the API call fails
    }
  };

  useEffect(() => {
    if (logoUrl) {
      setImageUrl(logoUrl);
      return;
    }

    const loadImageUrl = async () => {
      const url = await getImageUrl();
      if (url) {
        setImageUrl(url);
      } else {
        setImageError(true); // Set error if no URL is found
      }
    };

    loadImageUrl();
  }, [symbol, market_type, cryptoID]);

  const getFirstWord = (text: string) => {
    return text.split(/[^\w]/)[0]; // Split by any non-word character (space, punctuation, etc.)
  };

  const displayText = getFirstWord(symbol.toUpperCase());

  return (
    <Box
      sx={{
        width: 50, // Ensures the width is exactly 50px
        height: 50, // Matches the height of the image or fallback circle
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
      }}
    >
      {!imageError && imageUrl ? (
        <img
          src={imageUrl} // Dynamically fetch based on market_type
          alt={`${symbol} logo`}
          style={{ width: 50, height: 50, borderRadius: '50%' }} // Fixed width and height
          onError={() => setImageError(true)} // Set error state if image fails to load
        />
      ) : (
        // Fallback Circle with symbol text (smallest possible text that fits)
        <Box
          sx={{
            width: 50,
            height: 50,
            borderRadius: '50%',
            backgroundColor: 'gray',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="body2" // Use a small variant
            color="white"
            sx={{ fontSize: '0.75rem', textAlign: 'center', lineHeight: 1 }}
          >
            {displayText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Logo;
