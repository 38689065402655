import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Toolbar, TablePagination,
  Box, Typography,
  Container
} from '@mui/material';
import '../style/MarketTable.css';
import StockDrawer from './drawer'; 
import Logo from './logo';
import { cryptoAsset } from '../types/crypto';

// Custom hook to debounce a value
const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

// Function to get cached data if it exists and is still valid
const getCachedData = (key) => {
  const cachedData = localStorage.getItem(key);
  if (!cachedData) return null;

  const { data, expiry } = JSON.parse(cachedData);
  if (Date.now() > expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return data;
};

// Function to set cache with an expiration time
const setCache = (key, data, ttl = 300000) => { // 5 minutes in milliseconds
  const expiry = Date.now() + ttl;
  localStorage.setItem(key, JSON.stringify({ data, expiry }));
};

// Function to apply filters to the data
const applyFilter = (data, filterType) => {
  switch (filterType) {
    case 'gainers':
      return data.filter((item) => (item.quote?.USD?.percent_change_24h ?? 0) > 0);
    case 'losers':
      return data.filter((item) => (item.quote?.USD?.percent_change_24h ?? 0) < 0);
    case 'most-visited':
      return data.filter((item) => item.type === 'most-visited'); // Assuming 'most-visited' is a type in the data
    case 'new':
      return data.filter((item) => item.type === 'new'); // Assuming 'new' is a type in the data
    default:
      return data;
  }
};

const CryptoTable = () => {
  const [initialData, setInitialData] = useState<cryptoAsset[]>([]);
  const [filteredData, setFilteredData] = useState<cryptoAsset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300); // Debounced search term with 300ms delay
  const [filterType, setFilterType] = useState<'all' | 'gainers' | 'losers' | 'most-visited' | 'new'>('all');
  const [selectedSymbol, setSelectedSymbol] = useState<cryptoAsset | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const cachedData = getCachedData('cryptoData');

      if (cachedData) {
        setInitialData(cachedData);
        setFilteredData(cachedData); // Initialize filteredData to match initialData
        setLoading(false);
      } else {
        try {
          setLoading(true);
          const baseUrl = process.env.REACT_APP_API_BASE_URL;
          const response = await axios.get(`${baseUrl}/crypto-table-all-data`);
          setInitialData(response.data.data);
          setFilteredData(response.data.data); // Initialize filteredData to match initialData
          setCache('cryptoData', response.data.data);
        } catch (err) {
          setError('Error fetching market data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const handleOpenDrawer = (item: cryptoAsset) => {
    setSelectedSymbol(item);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedSymbol(null);
  };

  const handleFilterType = (type: 'all' | 'gainers' | 'losers' | 'most-visited' | 'new') => {
    setFilterType(type);
    setPage(0); // Reset to the first page
    // Apply filter to the initial data
    setFilteredData(applyFilter(initialData, type));
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(0); // Reset to the first page
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Apply search term on the original data without filtering by filterType
  const searchResults = debouncedSearchTerm === ''
  ? filteredData // Use filtered data if no search term
  : initialData.filter((item) =>
      (item.name ?? '').toLowerCase().includes(debouncedSearchTerm.toLowerCase()) || 
      (item.symbol ?? '').toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );


  // Paginated data based on search results or filtered data
  const paginatedData = searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container>
      <Box>
        <Typography variant="h2" component="h2" pb={2} pt={8}>
          Crypto Update
        </Typography>

        <TableContainer component={Paper} className="market-table">
          {/* Toolbar for Filters */}
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Button variant={filterType === 'all' ? 'contained' : 'outlined'} onClick={() => handleFilterType('all')} sx={{ marginRight: 1 }}>
                All
              </Button>
              <Button variant={filterType === 'gainers' ? 'contained' : 'outlined'} onClick={() => handleFilterType('gainers')} sx={{ marginRight: 1 }}>
                Gainers
              </Button>
              <Button variant={filterType === 'losers' ? 'contained' : 'outlined'} onClick={() => handleFilterType('losers')} sx={{ marginRight: 1 }}>
                Losers
              </Button>
              <Button variant={filterType === 'most-visited' ? 'contained' : 'outlined'} onClick={() => handleFilterType('most-visited')} sx={{ marginRight: 1 }}>
                Most Visited
              </Button>
              <Button variant={filterType === 'new' ? 'contained' : 'outlined'} onClick={() => handleFilterType('new')} sx={{ marginRight: 1 }}>
                New
              </Button>
            </Box>

            {/* Search bar */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <TextField
                label="Search by Name"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchTermChange}
                sx={{
                  input: { color: '#fff' },
                  label: { color: '#fff' },
                  fieldset: { borderColor: '#fff' },
                }}
              />
            </Box>
          </Toolbar>

          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Table className="market-table">
              <TableHead>
                <TableRow className="market-table-row">
                  <TableCell>Logo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Platform</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Percent Change</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((item: cryptoAsset) => (
                  <TableRow key={`${item.symbol}-${item.id}`} className="table-row">
                    <TableCell sx={{ maxWidth: '50px' }}>
                      <Logo symbol={item.symbol} market_type={item.type} cryptoID={item.id} logoUrl={undefined}/>
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.symbol}</TableCell>
                    <TableCell>{item.platform?.name ?? 'N/A'}</TableCell>
                    <TableCell>${item.quote?.USD?.price?.toFixed(2) ?? 'N/A'}</TableCell>
                    <TableCell className={(item.quote?.USD?.percent_change_24h ?? 0) > 0 ? 'percent-change-positive' : 'percent-change-negative'}>
                      {(item.quote?.USD?.percent_change_24h ?? 0).toFixed(2)}%
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" color="primary" className="table-button" onClick={() => handleOpenDrawer(item)}>
                        View More
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={searchResults.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <StockDrawer 
        open={drawerOpen} 
        onClose={handleCloseDrawer}
        symbol={selectedSymbol?.symbol} 
        cryptoID={selectedSymbol?.id} 
      />
      </Box>
    </Container>
  );
};

export default CryptoTable;
