import React, { useState, useEffect } from 'react';
import { Button, Modal, Tabs, Tab, Typography, TextField, Box, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import StockDrawer from './drawer'; // Import the StockDrawer component

interface SearchResult {
  symbol: string;
  name: string;
  cryptoID?: string;
}

const SearchButton = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [storedCryptoResults, setStoredCryptoResults] = useState<SearchResult[]>([]); // Store crypto results
  const [storedForexResults, setStoredForexResults] = useState<SearchResult[]>([]); // Store forex results
  const [storedCommoditiesResults, setStoredCommoditiesResults] = useState<SearchResult[]>([]); // Store commodities results
  const [storedETFResults, setStoredETFResults] = useState<SearchResult[]>([]); // Store ETF results
  const [storedStockResults, setStoredStockResults] = useState<SearchResult[]>([]); // Store stock results
  const [activeTab, setActiveTab] = useState('stocks');
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer state
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null); // Selected symbol state
  const [cryptoID, setcryptoID] = useState<string | null>(null);
  const handleSearchButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    // If search query is empty, clear the results
    if (query.length === 0) {
      setSearchResults([]);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newActiveTab: string) => {
    setActiveTab(newActiveTab);
    setSearchQuery(''); // Clear search query when switching tabs
    setSearchResults([]); // Clear results
  };

  const filterResults = (results: SearchResult[]) => {
    const filteredResults = results.filter(result => 
      result.name?.toLowerCase().includes(searchQuery?.toLowerCase()) || 
      result.symbol?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      // Don't perform a search if the query is empty
      setSearchResults([]);
      return;
    }

    // console.log('searching');
    setIsLoading(true);
    try {
      let url;
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      if (activeTab === 'stocks' && storedStockResults.length === 0) {
        url = `${baseUrl}/stocks-list-quote?searchQuery=${searchQuery}`; // Stock API
        const response = await axios.get(url);
        setStoredStockResults(response.data); // Store stock results
        setSearchResults(response.data);
      } else if (activeTab === 'crypto' && storedCryptoResults.length === 0) {
        const response = await fetch('/assets/allCryptoInfo.json');
        const data = await response.json();
    
        // Assuming the data in the file has a similar structure to the API response
        const cryptoResults = data.data.map((item: any) => ({
          symbol: item.symbol,
          name: item.name,
          cryptoID: item.id,
        }));
        
        // console.log(cryptoResults);
        setStoredCryptoResults(cryptoResults);
        setSearchResults(cryptoResults);
      } else if (activeTab === 'forex' && storedForexResults.length === 0) {
        url = `${baseUrl}/forex-list`; // Forex API
        const response = await axios.get(url);
        setStoredForexResults(response.data); // Store forex results
        setSearchResults(response.data);
      } else if (activeTab === 'commodities' && storedCommoditiesResults.length === 0) {
        url = `${baseUrl}/commodity-list`; // Commodities API
        const response = await axios.get(url);
        setStoredCommoditiesResults(response.data); // Store commodities results
        setSearchResults(response.data);
      } else if (activeTab === 'etf' && storedETFResults.length === 0) {
        url = `${baseUrl}/etf-list`; // ETF API
        const response = await axios.get(url);
        setStoredETFResults(response.data); // Store ETF results
        setSearchResults(response.data);
      } else {
        // Filter stored results if they exist
        switch (activeTab) {
          case 'stocks':
            filterResults(storedStockResults);
            break;
          case 'crypto':
            filterResults(storedCryptoResults);
            break;
          case 'forex':
            filterResults(storedForexResults);
            break;
          case 'commodities':
            filterResults(storedCommoditiesResults);
            break;
          case 'etf':
            filterResults(storedETFResults);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery, activeTab]);

  // Handle list item click to open the drawer
  const handleListItemClick = (symbol: string, cryptoID: string) => {
    setSelectedSymbol(symbol);
    setcryptoID(cryptoID);
    setDrawerOpen(true); // Open the drawer
    setIsPopupOpen(false); // Close the search modal
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleSearchButtonClick}
        startIcon={<SearchIcon />}
        sx={{
          borderRadius: '50%',
          padding: '6px 10px', // Adjust padding as needed
          minWidth: 'unset', // Remove minimum width
          border: 'none', // Remove border
        }}
      />
      <Modal 
        open={isPopupOpen} 
        onClose={() => setIsPopupOpen(false)} 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
        BackdropProps={{
          style: { backgroundColor: 'transparent' }, // Make backdrop transparent
        }}
      >
        <Box sx={{ 
          position: 'relative', 
          width: 400, // Fixed width for the modal
          bgcolor: 'white', 
          p: 4, 
          color: '#000',
          borderRadius: '8px', // Optional: rounded corners
          boxShadow: 3 // Optional: add shadow for a better appearance
        }}>
          <Button 
            onClick={() => setIsPopupOpen(false)} 
            sx={{ color: '#000', position: 'absolute', top: 10, right: 10, minWidth: 'unset', padding: '0' }} 
          >
            <CloseIcon />
          </Button>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Stocks" value="stocks" />
            <Tab label="ETFs" value="etf" />
            <Tab label="Crypto" value="crypto" />
            <Tab label="Forex" value="forex" />
            <Tab label="Commodities" value="commodities" />
          </Tabs>
          <Typography variant="h6">Search Results</Typography>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchInputChange}
            fullWidth
            slotProps={{
              input: {
                style: { color: '#000' }, // Set text color here
              },
            }}
          />
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}> {/* Scrollable area */}
              <List>
                {searchResults.slice(0, 10).map((result: SearchResult, index: number) => ( // Show 10 results at a time
                  <ListItem 
                    divider={true} 
                    key={index} 
                    sx={{ color: '#000', cursor: 'pointer' }}
                    onClick={() => handleListItemClick(result.symbol, result.cryptoID || '')} // Open drawer on click
                  >
                    <ListItemText 
                      primaryTypographyProps={{ style: { color: '#000' } }} 
                    >
                      {result.symbol} {result.name}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Drawer Component */}
      <StockDrawer 
        open={drawerOpen} 
        onClose={() => setDrawerOpen(false)} 
        symbol={selectedSymbol} 
        cryptoID={cryptoID} 
        type={activeTab}
      />
    </div>
  );
};

export default SearchButton;
