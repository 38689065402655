import React, { useEffect, useState } from 'react';
import { Box, Grid2, Typography, Alert } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from 'axios';
import StockDrawer from './drawer';
import Logo from './logo';
import { auth } from '../firebaseConfig';
import { cryptoAsset } from '../types/crypto';
import { IndividualStockCryptoQuote } from '../types/marketTable';

const defaultStockList: cryptoAsset[] = [
  { symbol: 'USDTUSD', id: 825, type: 'crypto' },
  { symbol: 'UNI', id: 26651, type: 'crypto' },
  { symbol: 'DOTUSD', id: 6636, type: 'crypto' },
  { symbol: 'SOL', id: 5426, type: 'crypto' },
  { symbol: 'ADAUSD', id: 2010, type: 'crypto' },
  { symbol: 'BTC', id: 1, type: 'crypto' },
  { symbol: 'POLUSD', id: 28321, type: 'crypto' },
];

export const CryptoRow = () => {
  const [topAssets, setTopAssets] = useState<IndividualStockCryptoQuote[]>([]);
  const [visibleAssets, setVisibleAssets] = useState<number>(6);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState<cryptoAsset | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const user = auth.currentUser;

  useEffect(() => {
    const fetchAssets = async () => {
      const symbols = defaultStockList.map((asset) => asset.symbol).join(',');
      const assetData = await fetchAssetsData(symbols);

      if (assetData.length === 0) {
        setErrorMessage('Failed to fetch asset data, showing default stocks.');
        const fallbackAssetData = await fetchAssetsData(defaultStockList.map((stock) => stock.symbol).join(','));
        const mergedFallbackAssets = remergeAssetData(fallbackAssetData, defaultStockList);
        setTopAssets(mergedFallbackAssets);
        // console.log('Top Assets:', mergedFallbackAssets);
      } else {
        const mergedAssetData = remergeAssetData(assetData, defaultStockList);
        setTopAssets(mergedAssetData);
        // console.log('Top Assets:', mergedAssetData);

      }
      
    };

    fetchAssets();
  }, [user]);

  const remergeAssetData = (fetchedData: any[], originalData: cryptoAsset[]) => {
    return fetchedData.map((asset) => {
      const originalAsset = originalData.find((item) => item.symbol === asset.symbol);
      return originalAsset
        ? {
            ...asset,
            id: originalAsset.id,
            type: originalAsset.type,
          }
        : asset;
    });
  };

  const fetchAssetsData = async (symbols: string) => {
    const baseUrl = 'https://financialmodelingprep.com/api/v3/quote';
    const apiKey = '9I5BcBDPrSAHGnszz7zCs0IXUhBPV7YS'; // Replace with your API key

    try {
      const response = await axios.get(`${baseUrl}/${symbols}?apikey=${apiKey}`);
      return response.data;
    } catch (error) {
      return [];
    }
  };

  const handleCardClick = (assetSelected: cryptoAsset) => {
    setSelectedSymbol(assetSelected);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSeeMore = () => {
    setVisibleAssets((prev) => prev + 6);
  };

  return (
    <Box>
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMessage}
        </Alert>
      )}

      {/* Desktop View: Grid2 layout */}
      <Box>
        <Grid2 container spacing={2}>
          {topAssets.slice(0, visibleAssets).map((asset, index) => (
            <Grid2 key={index} size={{ xs: 12, md: 2 }}>
              <Box
                sx={{
                  padding: '1rem',
                  backgroundColor: 'transparent',
                  color: '#000',
                  textAlign: 'center',
                  borderRight: index !== topAssets.length - 1 ? '1px solid #e0e0e0' : 'none',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  },
                }}
                onClick={() => handleCardClick(asset)}
              >
                {/* Logo and Symbol */}
              <Box display="flex" justifyContent="center" alignItems="center">
                  <Box sx={{ width: '50px', color: '#000' }}>
                    <Logo symbol={asset.symbol} cryptoID={asset.id} market_type={'crypto'} logoUrl={undefined} />
                  </Box>

                  {/* Symbol, Change Icon, and Change Percentage */}
                  <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                    {/* Symbol */}
                    <Typography variant="body2" color="#000">
                      {asset.symbol}
                    </Typography>
                  
                    {/* Change Icon */}
                    {(asset.price ?? 0) > 0 ? (
                      <ArrowUpwardIcon sx={{ color: 'green', ml: 1 }} />
                    ) : (
                      <ArrowDownwardIcon sx={{ color: 'red', ml: 1 }} />
                    )}

                    {/* Change Percentage */}
                    <Typography variant="body2" sx={{ color: (asset.changesPercentage ?? 0) > 0 ? 'green' : 'red', ml: 1 }}>
                      {(asset?.changesPercentage ?? 0).toFixed(2)}%
                    </Typography>
                  </Box>
                </Box>

                {/* Price */}
                <Typography variant="h5" sx={{ mt: 1 }} color="#000">
                  ${asset.price} USD
                </Typography>

              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>

     

      {/* Stock Drawer for more details */}
      <StockDrawer 
        open={drawerOpen} 
        onClose={handleDrawerClose}
        symbol={selectedSymbol?.symbol} 
        cryptoID={selectedSymbol?.id} 
      />
    </Box>
  );
};

export default CryptoRow;
