// src/redux/reducers/topStocksSlice.ts
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reload: false, // Initial reload state
};

const topStocksSlice = createSlice({
    name: 'topStocks',
    initialState,
    reducers: {
        toggleReload: (state) => {
            state.reload = !state.reload; // Toggle the reload state
        },
    },
});

export const { toggleReload } = topStocksSlice.actions;
export default topStocksSlice.reducer;
