import { getFirestore, doc, updateDoc, arrayUnion, getDoc, arrayRemove } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const db = getFirestore();
const auth = getAuth();

interface FavoriteAsset {
  symbol: string;
  cryptoID?: string; // Optional property
}

/**
 * Adds an asset to the user's list of favorite assets.
 * @param {string} symbol - The stock/crypto symbol to be added.
 * @param {string} type - The type of the asset (e.g., stock, crypto).
 * @param {string} [cryptoID] - Optional crypto ID for cryptocurrencies.
 */
export const favoriteAsset = async (symbol: string, cryptoID?: string): Promise<void> => {
  const user = auth.currentUser;

  if (user) {
    const userDocRef = doc(db, 'users', user.uid);

    try {
      // Ensure type is defined, and handle the case where it might be undefined
  

      // Create the asset object to be added
      const asset: FavoriteAsset = { symbol };
      
      // Include cryptoID only if it's defined
      if (cryptoID) {
        asset.cryptoID = cryptoID;
      }

      // Log the asset before adding
      console.log('Adding asset to favorites:', asset);
  
      // Update the user's favorite assets by adding the new asset to the array
      await updateDoc(userDocRef, {
        favoriteAssets: arrayUnion(asset), // This will add the asset object to the array
      });

      console.log(`${symbol} has been added to favorites.`);
    } catch (error) {
      console.error('Error adding asset to favorites:', error);
    }
  } else {
    console.error('User is not authenticated.');
  }
};

/**
 * Removes an asset from the user's list of favorite assets.
 * @param {string} symbol - The stock/crypto symbol to be removed.
 * @param {string} type - The type of the asset (e.g., stock, crypto).
 */
export const unfavoriteAsset = async (symbol: string, cryptoID?: string): Promise<void> => {
  const user = auth.currentUser;

  if (user) {
    const userDocRef = doc(db, 'users', user.uid);

    try {
      // Fetch the user's document to get the current favorite assets
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        const favoriteAssets: FavoriteAsset[] = data?.favoriteAssets || [];

        // Find the exact object that matches the symbol and type
        const assetToRemove = favoriteAssets.find(asset => asset.symbol === symbol || asset.cryptoID === cryptoID);

        if (assetToRemove) {
          // Remove the exact object from the array (must match the symbol and type)
          await updateDoc(userDocRef, {
            favoriteAssets: arrayRemove(assetToRemove)
          });
          console.log(`${symbol} has been removed from favorites.`);
        } else {
          console.log(`Asset with symbol ${symbol}  not found in favorites.`);
        }
      }
    } catch (error) {
      console.error('Error removing asset from favorites:', error);
    }
  } else {
    console.error('User is not authenticated.');
  }
};

/**
 * Checks if a given asset is favorited by the current user.
 * @param {string} symbol - The stock/crypto symbol to check.
 * @param {string} type - The type of the asset (e.g., stock, crypto).
 * @returns {Promise<boolean>} - Whether the asset is in the user's favorites.
 */
export const isFavorite = async (symbol: string, cryptoID?: string): Promise<boolean> => {
  const user = auth.currentUser;

  console.log('Checking favorite status for:', symbol, cryptoID);

  if (user) {
    const userDocRef = doc(db, 'users', user.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        const favoriteAssets: FavoriteAsset[] = data?.favoriteAssets || [];

        console.log('Favorite assets:', favoriteAssets);
        // Check if both the symbol and type match
        if (cryptoID) {
          return favoriteAssets.some(asset => asset.cryptoID === cryptoID);
          
        } else {
          return favoriteAssets.some(asset => asset.symbol === symbol);
          
        }
       
      }
    } catch (error) {
      console.error('Error fetching favorite status:', error);
    }
  } else {
    console.error('User is not authenticated.');
  }

  return false;
};
