// src/hooks/useAuth.tsx
import React, { useContext, useEffect, useState, createContext } from 'react';
import { User, onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

// Define the AuthContext type
interface AuthContextType {
  currentUser: User | null;
  username: string | null;
  loading: boolean;
  signOut: () => Promise<void>;
}

// Create the AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to access the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Provider component for AuthContext
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [username, setUsername] = useState<string | null>(null); // State for username
  const [loading, setLoading] = useState(true);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoading(false);
      
      if (user) {
        // Fetch username from Firestore when user logs in
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        
        // console.log('User:', user);
        if (userDoc.exists()) {
          // console.log('User data:', userDoc.data());
          setUsername(userDoc.data()?.username || null); // Set username if it exists
        } else {
          setUsername(null); // Reset if no user data is found
        }
      } else {
        setUsername(null); // Reset when no user is logged in
      }
    });
    return unsubscribe;
  }, []);

  const signOut = async () => {
    await firebaseSignOut(auth);
    setCurrentUser(null);
    setUsername(null); // Reset username on sign out
  };

  return (
    <AuthContext.Provider value={{ currentUser, username, loading, signOut }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
