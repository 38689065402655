import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Container,
  ListItemButton,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { auth } from '../firebaseConfig';
import SearchButton from './GlobalSearch';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredMenu, setHoveredMenu] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => setUser(user));
    return () => unsubscribe();
  }, []);

  const dropdownItems: { [key: string]: { name: string; url: string }[] } = {
    Markets: [
      { name: 'Stocks', url: '/markets/stocks' },
      { name: 'Indices', url: '/markets/indices' },
      { name: 'Commodities', url: '/markets/commodities' },
      { name: 'Cryptos', url: '/markets/crypto' },
      { name: 'Forex', url: '/markets/forex' },
    ],
    News: [
      { name: 'Financial Markets', url: '/news?topic=financial_markets' },
      { name: 'Overview', url: '/news?topic=overview' },
      { name: 'Corporations', url: '/news?topic=corporations' },
      { name: 'Real Estate', url: '/news?topic=real_estate' },
      { name: 'Market News', url: '/news?topic=market_news' },
      { name: 'Stocks', url: '/news?topic=stocks' },
      { name: 'Crypto', url: '/news?topic=crypto' },
    ],
    Analysis: [
      { name: 'All', url: '/forum' },
      { name: 'Market Analysis', url: '/forum/Analysis' },
      { name: 'Forum', url: '/forum/Insights' },
    ],
    More: [
      { name: 'Education', url: '/coming-soon' },
      { name: 'About', url: 'about' },
      { name: 'Calendar', url: '/coming-soon' },
    ],
  };
  

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleDropdownClick = (menuName: string) => {
    setOpenDropdown(openDropdown === menuName ? null : menuName);
  };

  const handleLogout = () => {
    auth.signOut();
    setUser(null); // Clear user state after logout
  };

  const drawerList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItemButton component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItemButton>

        {Object.keys(dropdownItems).map((item) => (
          <React.Fragment key={item}>
            <ListItemButton onClick={() => handleDropdownClick(item)}>
              <ListItemText primary={item} />
              {openDropdown === item ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDropdown === item} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {dropdownItems[item].map((submenu) => (
                  <ListItemButton
                    key={submenu.name}
                    component={Link} // Make it a link
                    to={submenu.url} // Provide the URL to navigate
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={submenu.name} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}

        {/* Calendar button under More */}
        <ListItemButton component={Link} to="/coming-soon">
          <ListItemText primary="Calendar" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#2e003e', boxShadow: 'none' }}>
      <Container>
        <Toolbar sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: '#fff', fontWeight: 'bold' }}>
            Charts<span style={{ color: '#8e44ad' }}>Over</span>
          </Typography>

          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerList()}
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', gap: 3 }}>
              {Object.keys(dropdownItems).map((item) => (
                <Box
                  key={item}
                  onMouseEnter={() => setHoveredMenu(item)}
                  onMouseLeave={() => setHoveredMenu(null)}
                  sx={{ position: 'relative' }}
                >
                  <Button
                    color="inherit"
                    sx={{
                      color: '#fff',
                      '&:hover': {
                        color: '#007bff',
                        backgroundColor: 'transparent',
                      },
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                    }}
                  >
                    {item}
                    <KeyboardArrowDown />
                  </Button>
                  {hoveredMenu === item && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        backgroundColor: '#2e003e',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: 1,
                        zIndex: 1000,
                      }}
                    >
                      {dropdownItems[item]?.map((submenu) => (
                        <MenuItem
                          key={submenu.name}
                          component={Link}
                          to={submenu.url}
                          sx={{
                            padding: '10px 16px',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#4b006e',
                            },
                          }}
                        >
                          {submenu.name}
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}

          {/* Search and Profile buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SearchButton />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              sx={{ color: '#fff' }}
            >
              <AccountCircle />
            </IconButton>
          </Box>

          {/* User Menu */}
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
          >
            {user ? (
              <>
                <MenuItem component={Link} to="/profile" onClick={() => setMenuAnchorEl(null)}>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </>
            ) : (
              <>
                <MenuItem component={Link} to="/login" onClick={() => setMenuAnchorEl(null)}>
                  Login
                </MenuItem>
                <MenuItem component={Link} to="/signup" onClick={() => setMenuAnchorEl(null)}>
                  Sign Up
                </MenuItem>
              </>
            )}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
