import React from 'react';
import { Box, Typography, IconButton, Grid2, Container } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#3A0D56', // Background color
        color: 'white',              // Text color
        py: 4,                       // Padding top and bottom
      }}
    >
      <Container>
        <Grid2 container justifyContent="space-between" spacing={4}>
          {/* Logo and Social Media Section */}
          <Grid2 size={{ xs: 12, md: 3 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              ChartsOver
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <a href="https://www.instagram.com/chartsover.io/" target="_blank">
              <IconButton sx={{ color: 'white' }}>
                <InstagramIcon />
              </IconButton></a>
              {/* <IconButton sx={{ color: 'white' }}>
                <FacebookIcon />
              </IconButton> */}
              <a href="https://x.com/ChartsOver" target="_blank"><IconButton sx={{ color: 'white' }}>
                <TwitterIcon />
              </IconButton></a>
              {/* <IconButton sx={{ color: 'white' }}>
                <YouTubeIcon />
              </IconButton> */}
            </Box>
            <Typography variant="body2">2024. All rights reserved</Typography>
          </Grid2>

          {/* About Us Section */}
          <Grid2 size={{ xs: 12, md: 9 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
              About Us
            </Typography>
            <Typography variant="body2">
  Our website is currently in trial version and we are constantly improving to provide you with the best experience. Feel free to share your feedback about our application with us, Feel free to suggest any new features you would like to see. Your opinions are very valuable to us! By following us on our social media accounts, you can be a part of our community and be the first to be informed about the latest announcements. For advertising and collaborations, please send an e-mail to  
  <a href="mailto:chartsover@gmail.com" style={{ color: 'white', textDecoration: 'none' }}>
    chartsover@gmail.com
  </a>
</Typography>

          </Grid2>

        
        </Grid2>
      </Container>
    </Box>
  );
};

export default Footer;
