// Exporting the interfaces and initialState

export interface StockHighLevel {
  symbol: string;
  name: string;
  price: number;
  percent_change: number;
  logo: string;
  market_type: string
}

export interface MoversData {
  gainers: StockHighLevel[];
  losers: StockHighLevel[];
}

// Define the initial state
export interface MarketTable {
  movers: MoversData | null;
  loading: boolean;
  error: string | null;
}

export const initialState: MarketTable = {
  movers: null,
  loading: false,
  error: null,
};

export interface  IndividualStockCryptoQuote {
  id? : string;
  symbol: string;
  name: string;
  price: number;
  changesPercentage: number;
  change: number;
  dayLow: number;
  dayHigh: number;
  yearHigh: number;
  yearLow: number;
  marketCap: number;
  priceAvg50: number;
  priceAvg200: number;
  exchange: string;
  volume: number;
  avgVolume: number;
  open: number;
  previousClose: number;
  eps: number;
  pe: number;
  earningsAnnouncement: string;
  sharesOutstanding: number;
  timestamp: number;
};


