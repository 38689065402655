import React, { useState } from 'react';
import { Container, TextField, Button, Box, Chip, Typography, MenuItem, Select, InputLabel, FormControl, Alert } from '@mui/material';
import { db, storage } from '../firebaseConfig';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'; 
import RichTextEditor from '../components/RichTextEditor'; 
import DeleteIcon from '@mui/icons-material/Delete';

const CreatePost: React.FC = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState('');
  const [images, setImages] = useState<string[]>([]);
  const [category, setCategory] = useState('insights');
  const { currentUser, username } = useAuth(); 
  const navigate = useNavigate();

  const handleTagAdd = () => {
    if (newTag && !tags.includes(newTag)) {
      setTags((prevTags) => [...prevTags, newTag]);
      setNewTag('');
    }
  };

  const handleTagDelete = (tagToDelete: string) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleImageUpload = async (file: File) => {
    if (!file) return; // Ensure file is provided

    const imageRef = ref(storage, `forumImages/${uuidv4()}`);
    try {
      await uploadBytes(imageRef, file);
      const url = await getDownloadURL(imageRef);
      setImages((prevImages) => [...prevImages, url]);
      setCategory('analysis'); // Reset category to default}
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to upload image. Please try again."); // User feedback
    }
  };

  const handleSubmit = async () => {
    if (!title || !content) {
      alert("Title and content are required."); // User feedback for validation
      return;
    }

    try {
      await addDoc(collection(db, 'posts'), {
        title,
        content,
        tags,
        images,
        username,
        category,
        createdAt: Timestamp.now(),
      });
      navigate('/forum');
    } catch (error) {
      console.error("Error creating post:", error);
      alert("Failed to create post. Please try again."); // User feedback
    }
  };

  return (
    <Container
      sx={{
        backgroundColor: '#2e003e',
        padding: '20px',
        borderRadius: '8px',
        color: '#FFFFFF',
      }}
      maxWidth="md"
    >
      {currentUser ? (
        <>
          <Typography variant="h4" gutterBottom sx={{ color: '#FFFFFF' }}>
            Create a New Post
          </Typography>

          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            sx={{
              backgroundColor: '#FFFFFF',
              color: '#000', 
              borderRadius: '8px',
              input: { color: '#000' },
              padding: '10px',
              marginBottom: '20px',
            }}
          />

          <Typography variant="h6" gutterBottom>
            Create Post
          </Typography>
          <RichTextEditor value={content} onChange={setContent} />

          {/* <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{ color: '#FFFFFF' }}>Category</InputLabel>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value as string)}
              sx={{ backgroundColor: '#FFFFFF', color: '#000' }}
            >
              <MenuItem value="Analysis">Analysis</MenuItem>
              <MenuItem value="Ideas">Ideas</MenuItem>
            </Select>
          </FormControl> */}
          <Alert variant="outlined" color="info" sx={{ color: '#FFFFFF' }}>
  By uploading an analysis image, your post will be categorized as an analysis post. Otherwise, your post will be located within insights. 
</Alert>
          
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
              Tags
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                  deleteIcon={<DeleteIcon sx={{ color: '#FFFFFF' }} />}
                  sx={{ backgroundColor: '#8e44ad', color: '#FFFFFF' }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                label="New Tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                InputLabelProps={{ style: { color: '#FFFFFF' } }}
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '5px',
                  input: { color: '#000' },
                }}
              />
              <Button variant="contained" onClick={handleTagAdd} sx={{ backgroundColor: '#8e44ad', color: '#FFFFFF' }}>
                Add Tag
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
              Upload Images
            </Typography>
            <input
              type="file"
              onChange={(e) => handleImageUpload(e.target.files![0])}
              style={{ color: '#FFFFFF' }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 3, backgroundColor: '#8e44ad', color: '#FFFFFF', '&:hover': { backgroundColor: '#7d3c98' } }}
          >
            Create Post
          </Button>
        </>
      ) : (
        <Alert severity="info" sx={{ backgroundColor: '#8e44ad', color: '#FFFFFF' }}>
          Please <Button onClick={() => navigate('/login')} sx={{ color: '#FFFFFF', textDecoration: 'underline' }}>login</Button> to create a new forum post.
        </Alert>
      )}
    </Container>
  );
};

export default CreatePost;
