import React from 'react';
import { Box, Container } from '@mui/material';
import TopStocks from './topStocks';
import MarketTable from './marketTable';
import NewsPage from './News';
import CryptoRow from './cryptoRow';
import StocksTable from './StocksTable';

const HomePage = () => {
    return (
        <>
            <Container>
                <Box sx={{ pb: 6, pt: 6 }}>
                <TopStocks />
                </Box>
            </Container>

            {/* Full-width CryptoRow */}
            <Box sx={{ width: '100%', bgcolor: '#fff', color: '#000', py: 4, mt: 12, mb: 10 }}>
                <CryptoRow />
            </Box>

            <Container sx={{pt: 6}}>
                <Box>
                <StocksTable defaultRowsPerPage={5} />

                </Box>

                <Box sx={{pt: 6}}>
                <NewsPage maxResults={9} defaultCategory="Overview" />

                </Box>
            </Container>
        </>
    );
};

export default HomePage;