import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC7uF44CAwek9x4XdjLSFcqe87dpPOa_34",
  authDomain: "chartsapp-333338.firebaseapp.com",
  projectId: "chartsapp-333338",
  storageBucket: "chartsapp-333338.appspot.com",
  messagingSenderId: "1002451065329",
  appId: "1:1002451065329:web:668dc5b342bc4155640c50",
  measurementId: "G-YBN4V0XZYY"
};

// Initialize Firebase only if no app is already initialized
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
