import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Alert,
  Grid2,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ArticleItem from "./ArticleItem";

// Define the Article type based on your API response
interface Article {
  title: string;
  url: string;
  time_published: string;
  authors: string[];
  summary: string;
banner_image?: string | undefined;
  source: string;
  category_within_source: string;
  source_domain: string;
  topics: Array<{
    topic: string;
    relevance_score: string;
  }>;
  overall_sentiment_score: number;
  overall_sentiment_label: string;
  ticker_sentiment: Array<{
    ticker: string;
    relevance_score: string;
    ticker_sentiment_score: number;
    ticker_sentiment_label: string;
  }>;
}

const categories = [
  "Financial Markets",
  "Overview",
  "Corporations",
  "Real Estate",
  "Stocks",
  "Crypto",
];
// Cache for news articles
const newsCache: Record<string, { articles: Article[]; timestamp: number }> = {};
// const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
const CACHE_DURATION = 15; // 15 minutes in milliseconds
const baseUrl = process.env.REACT_APP_API_BASE_URL;

interface NewsPageProps {
  maxResults?: number;
  defaultCategory?: string;
}

const NewsPage = ({
  maxResults,
  defaultCategory = "Overview",
}: NewsPageProps) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(defaultCategory); // Default to Overview
  const [query, setQuery] = useState<string | null>(null); // Track query parameter
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error message state
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const location = useLocation();

  // Function to generate cache key based on category
  const getCacheKey = (category: string) => {
    return category.toLowerCase();
  };

  const fetchNews = async (category: string) => {
    setIsLoading(true); // Start loading
    try {
      const topics = category.toLowerCase().replace(/\s+/g, "_");
      const url = `${baseUrl}/news?topics=${topics}`;
      console.log("Fetching URL:", url);

      const response = await axios.get(url);

      // Log the entire response
      console.log("Full API Response:", response);

      // Access the newsResponse array
      const apiArticles = response.data.newsResponse;
      console.log("Response Data (newsResponse):", apiArticles);

      // Check if apiArticles is an array
      console.log("Type of response.data.newsResponse:", typeof apiArticles);
      console.log("Is response.data.newsResponse an array?", Array.isArray(apiArticles));

      if (!Array.isArray(apiArticles)) {
        console.error("API response is not an array as expected.");
        console.log("Actual response.data:", response.data);
        throw new Error("Invalid API response format.");
      }

      // Log the number of articles received
      console.log(`Number of articles received: ${apiArticles.length}`);

      // Optional: Log first few articles for inspection
      console.log("First 3 articles:", apiArticles.slice(0, 3));

      // Filter out unwanted articles
      const filteredArticles = apiArticles.filter((article: Article) => {
        const summaryLower = article.summary?.toLowerCase() || "";
        const titleLower = article.title?.toLowerCase() || "";
        const excludedPhrases = [
          "before you continue",
          "another phrase to exclude",
        ];
        const isExcluded = excludedPhrases.some(
          (phrase) =>
            summaryLower.includes(phrase) || titleLower.includes(phrase)
        );
        if (isExcluded) {
          console.log(`Excluding article: ${article.title}`);
        }
        return !isExcluded;
      });

      console.log(
        `Number of articles after filtering: ${filteredArticles.length}`
      );

      // Save to cache
      const cacheKey = getCacheKey(category);
      newsCache[cacheKey] = {
        articles: filteredArticles,
        timestamp: Date.now(),
      };
      console.log("Cache Key:", cacheKey);
      console.log("Cached Articles:", newsCache[cacheKey]);

      setArticles(filteredArticles);
      setErrorMessage(null); // Clear error message on success
    } catch (error: any) {
      // Handle errors (network issues, invalid responses, etc.)
      console.error("Error fetching news:", error);
      setErrorMessage(
        error.message ||
          "An error occurred while fetching news. Please try again later."
      );
    } finally {
      setIsLoading(false); // Stop loading regardless of success or failure
      console.log("Loading state set to false.");
    }
  };

  const handleCategoryClick = (category: string) => {
    if (category !== selectedCategory) {
      console.log(`Category changed from ${selectedCategory} to ${category}`);
      setSelectedCategory(category); // Update the selected category only if it's different
      setQuery(null); // Reset query when switching categories
    } else {
      console.log(`Category ${category} is already selected. No action taken.`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const topic = params.get("topic");
    const queryParam = params.get("query");

    if (topic) {
      const formattedTopic = topic.replace(/_/g, " ").toLowerCase();
      const matchingCategory = categories.find(
        (category) => category.toLowerCase() === formattedTopic
      );
      if (matchingCategory) {
        console.log(`URL topic found: ${matchingCategory}`);
        setSelectedCategory(matchingCategory);
      } else {
        console.warn(`No matching category found for topic: ${topic}`);
        setSelectedCategory(defaultCategory);
      }
    } else {
      console.log("No topic parameter found in URL. Using default category.");
      setSelectedCategory(defaultCategory);
    }

    setQuery(queryParam);
  }, [location.search, defaultCategory]);

  useEffect(() => {
    if (selectedCategory) {
      const cacheKey = getCacheKey(selectedCategory);
      console.log(`Selected Category: ${selectedCategory}`);
      console.log(`Cache Key: ${cacheKey}`);

      // Check cache
      if (
        newsCache[cacheKey] &&
        Date.now() - newsCache[cacheKey].timestamp < CACHE_DURATION
      ) {
        console.log("Fetching articles from cache.");
        setArticles(newsCache[cacheKey].articles);
        setErrorMessage(null); // Clear error message
      } else {
        console.log("Fetching articles from API.");
        fetchNews(selectedCategory); // Fetch from API
      }
    }
  }, [selectedCategory]); // Removed 'query' from dependencies as it's not used in fetchNews

  const displayedArticles = maxResults
    ? articles.slice(0, maxResults)
    : articles;

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Typography variant="h3" align="center" gutterBottom>
        {selectedCategory ? `${selectedCategory} News` : "News Dashboard"}
      </Typography>

      {/* Category Buttons */}
      <Grid2
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          overflowX: "auto", // Enable horizontal scrolling if needed
          padding: "10px 0",
        }}
      >
        {categories.map((category) => (
          <Grid2 key={category} size={{ sm: "auto" }}>
            <Button
              variant={selectedCategory === category ? "contained" : "outlined"}
              onClick={() => handleCategoryClick(category)}
              startIcon={
                isLoading && selectedCategory === category ? (
                  <CircularProgress size={16} />
                ) : null
              }
            >
              {category}
            </Button>
          </Grid2>
        ))}
      </Grid2>

      {/* Loading Alert */}
      {isLoading && (
        <Alert severity="info" sx={{ marginTop: "20px" }}>
          Loading {selectedCategory} news...
        </Alert>
      )}

      {/* Error Alert with Retry */}
      {errorMessage && (
        <Alert severity="error" sx={{ marginTop: "20px" }}>
          {errorMessage}
          <Button
            onClick={() => fetchNews(selectedCategory)}
            color="inherit"
            size="small"
            sx={{ marginLeft: "10px" }}
          >
            Retry
          </Button>
        </Alert>
      )}

      {/* Articles and Loader */}
      <Box position="relative" sx={{ marginTop: "30px" }}>
        {/* Loader without Background */}
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {/* Articles Grid */}
        <Grid2
          container
          spacing={3}
          sx={{
            marginTop: "50px",
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          {isLoading
            ? Array.from(new Array(6)).map((_, index) => (
                <Grid2
                  key={index}
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height={200} />
                </Grid2>
              ))
            : displayedArticles.map((article, index) => (
                <Grid2
                  key={index}
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                  }}
                >
                  <ArticleItem article={article} />
                </Grid2>
              ))}
        </Grid2>
      </Box>
    </Container>
  );
};

export default NewsPage;