// Import required libraries and components
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Chip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebaseConfig';
import { useAuth } from '../hooks/useAuth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import RichTextEditor from './RichTextEditor';
import '../style/forum.css';

// Define the interfaces for Post and Comment
interface Post {
  title: string;
  content: string;
  images?: string[] | string | null;
  username: string;
  comments?: Comment[];
  tags: string[];
}

interface Comment {
  content: string;
  user: string;
  images: string[];
}

// Define the PostView component
const PostView: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');
  const [imageUpload, setImageUpload] = useState<File | null>(null);
  const { currentUser, username } = useAuth();
  const navigate = useNavigate();

  const tagClick = (tag: string) => {
    navigate(`/forum?search=${tag}`);
  };

  useEffect(() => {
    const fetchPost = async () => {
      const postDoc = await getDoc(doc(db, 'posts', postId!));
      if (postDoc.exists()) {
        setPost(postDoc.data() as Post);
      }
    };

    const fetchComments = async () => {
      const commentsSnapshot = await getDocs(collection(db, `posts/${postId}/comments`));
      const commentsData = commentsSnapshot.docs.map((doc) => doc.data() as Comment);
      setComments(commentsData);
    };

    fetchPost();
    fetchComments();
  }, [postId]);

  const handleCommentSubmit = async () => {
    if (!newComment) return;

    const images: string[] = [];
    if (imageUpload) {
      const imageRef = ref(storage, `commentImages/${uuidv4()}`);
      await uploadBytes(imageRef, imageUpload);
      const imageUrl = await getDownloadURL(imageRef);
      images.push(imageUrl);
    }

    await addDoc(collection(db, `posts/${postId}/comments`), {
      content: newComment,
      user: username || 'Anonymous',
      images,
      timestamp: new Date(),
    });

    setNewComment('');
    setImageUpload(null);

    const updatedComments = await getDocs(collection(db, `posts/${postId}/comments`));
    setComments(updatedComments.docs.map((doc) => doc.data() as Comment));
  };

  if (!post) return <Typography>Loading...</Typography>;

  // Helper function to render images with a max width and centered
  const renderImages = (images: string | string[]) => {
    const imageStyle = { 
      maxWidth: '75%', 
      margin: '0 auto', 
      display: 'block', 
      marginBottom: '10px' 
    };

    if (typeof images === 'string') {
      return <img src={images} alt="post-img" style={imageStyle} />;
    }
    return images.map((image, index) => (
      <img key={index} src={image} alt={`post-img-${index}`} style={imageStyle} />
    ));
  };

  return (
    <Container className='post-content'>
      {/* Post Title and Username */}
      <Typography variant="h4" gutterBottom>{post.title}</Typography>
      <Typography variant="subtitle1" gutterBottom>by {post.username || 'Unknown'}</Typography>

      {/* Render images if they exist */}
      {post.images && (
        <Box sx={{ mb: 3 }}>
          {Array.isArray(post.images) ? renderImages(post.images) : renderImages(post.images)}
        </Box>
      )}

      {/* Post Content */}
      <div dangerouslySetInnerHTML={{ __html: post.content }} className="post-content" />

      {/* Display Tags */}
      {post.tags?.map((tag, index) => (
        <Chip 
          key={index} 
          variant="outlined" 
          onClick={() => tagClick(tag)}
          label={tag} 
          sx={{ margin: '5px' }} 
        />
      ))}

      {/* Comments Section */}
      <Typography variant="h6" sx={{ marginTop: '20px' }}>Comments</Typography>
      {comments.length === 0 ? (
        <p>Be the first to comment, comment now!</p>
      ) : (
        <Box sx={{ mb: 2, border: '1px solid #ccc', padding: '10px', borderRadius: '8px' }}>
          {comments.map((comment, index) => (
            <Box key={index} sx={{ mb: 2, border: '1px solid #ccc', padding: '10px', borderRadius: '8px' }}>
              <Typography variant="body2"><strong>{comment.user}</strong>: </Typography>
              <span dangerouslySetInnerHTML={{ __html: comment.content }} />
              
              {comment?.images?.map((image, imgIndex) => (
                <img key={imgIndex} src={image} alt={`comment-img-${imgIndex}`} style={{ maxWidth: '100px', marginTop: '10px', margin: '0 auto', display: 'block' }} />
              ))}
            </Box>
          ))}
        </Box>
      )}

      {/* Add Comment Section */}
      {currentUser && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Add a Comment</Typography>
          <RichTextEditor value={newComment} onChange={setNewComment} />
          
          {/* Image Upload */}
          <input
            type="file"
            onChange={(e) => setImageUpload(e.target.files ? e.target.files[0] : null)}
            style={{ marginBottom: '20px' }}
          />

          {/* Submit Button */}
          <Button variant="contained" onClick={handleCommentSubmit} sx={{ mt: 2 }}>
            Submit Comment
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default PostView;
