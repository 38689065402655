import React, { useEffect, useState } from 'react';
import {
  Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Toolbar, 
  TablePagination, Box, Typography
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import StockDrawer from './drawer';

interface Props {
  type: 'commodity' | 'forex' | 'crypto' | 'indices' | 'stocks'; 
}

const MarketTableCommodities = ({ type }: Props) => {
  const [marketData, setMarketData] = useState<MarketDataItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState<'price' | 'change' | 'volume'>('price');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);

  interface MarketDataItem {
    symbol: string;
    name: string;
    price: number;
    changesPercentage: number;
    change: number;
    dayLow: number;
    dayHigh: number;
    yearHigh: number;
    yearLow: number;
    marketCap: number | null;
    priceAvg50: number;
    priceAvg200: number;
    exchange: string;
    volume: number | null;
    avgVolume: number | null;
    open: number;
    previousClose: number;
    eps: number | null;
    pe: number | null;
    earningsAnnouncement: string | null;
    sharesOutstanding: number | null;
    timestamp: number;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/${type}-list`);
        const data: MarketDataItem[] = await response.json();
        setMarketData(data);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch ${type} market data`);
        setLoading(false);
      }
    };
    fetchData();
  }, [type]);

  const formatPrice = (price: number) => 
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);

  const sortedData = [...marketData].sort((a, b) => {
    let valA = a[sortKey];
    let valB = b[sortKey];
    if (sortKey === 'change') {
      valA = a.changesPercentage;
      valB = b.changesPercentage;
    }
    return sortOrder === 'asc' ? (valA! > valB! ? 1 : -1) : (valA! < valB! ? 1 : -1);
  });

  const filteredData = sortedData.filter((item: MarketDataItem) =>
    (item.symbol && item.symbol.toLowerCase().includes(searchTerm.toLowerCase())) || 
    (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleOpenDrawer = (symbol: string) => {
    setSelectedSymbol(symbol);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedSymbol(null);
  };

  const handleSort = (key: 'price' | 'change' | 'volume') => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('desc');
    }
  };

  const renderSortIcon = (key: 'price' | 'change' | 'volume') => {
    if (sortKey !== key) return null;
    return sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />;
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h2" component="h2" pb={2}>
        {type.charAt(0).toUpperCase() + type.slice(1)} Market Update
      </Typography>

      <TableContainer component={Paper} className="market-table">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Button variant="outlined" onClick={() => handleSort('price')} sx={{ marginRight: 1 }}>
              Sort by Price {renderSortIcon('price')}
            </Button>
            {type !== 'stocks' && (
              <>
                <Button variant="outlined" onClick={() => handleSort('change')} sx={{ marginRight: 1 }}>
                  Sort by Change {renderSortIcon('change')}
                </Button>
                <Button variant="outlined" onClick={() => handleSort('volume')} sx={{ marginRight: 1 }}>
                  Sort by Volume {renderSortIcon('volume')}
                </Button>
              </>
            )}
          </Box>
          <TextField
            label="Search by Name or Symbol"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Toolbar>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <Table className="market-table">
            <TableHead>
              <TableRow className="market-table-row">
                <TableCell>Name</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Price</TableCell>
                {type !== 'stocks' && <TableCell>Percent Change</TableCell>}
                <TableCell>Volume</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item: MarketDataItem) => (
                <TableRow key={item.symbol} className="table-row">
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>{item?.symbol}</TableCell>
                  <TableCell>{formatPrice(item.price)}</TableCell>
                  {type !== 'stocks' && (
                    <>
                      <TableCell className={item?.changesPercentage > 0 ? 'percent-change-positive' : 'percent-change-negative'}>
                        {item?.changesPercentage?.toFixed(2)}%
                      </TableCell>
                      <TableCell>{item.volume?.toLocaleString() || 'N/A'}</TableCell>
                    </>
                  )}

                  
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleOpenDrawer(item.symbol)}>
                      View More
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
        />
      </TableContainer>

      <StockDrawer open={drawerOpen} onClose={handleCloseDrawer} symbol={selectedSymbol} />
    </Container>
  );
};

export default MarketTableCommodities;
