import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from react-dom/client in React 18
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'; // Assuming you're using redux
import App from './App';
import store from './store'; // Assuming you have a Redux store
import { AuthProvider } from './hooks/useAuth';

const container = document.getElementById('root');

if (container) {
  const root = ReactDOM.createRoot(container); // Use createRoot for React 18

  root.render(
    <Provider store={store}>
       <AuthProvider>
       <BrowserRouter>
        <App />
      </BrowserRouter>
       </AuthProvider>
    </Provider>
  );
} else {
  console.error("Root container not found!");
}
