import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Chip, TextField, Box, Card, CardContent, CardActionArea, CardActions, CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { getDocs, collection, query, where, orderBy, Timestamp, DocumentData } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../style/forum.css';

interface Post {
  id: string;
  title: string;
  content: string;
  tags: string[];
  category: string;
  timestamp: Timestamp;
  images: string[];
}

interface TruncatedContentProps {
  content: string;
}

interface TagFrequency {
  tag: string;
  count: number;
}

const TruncatedContent: React.FC<TruncatedContentProps> = ({ content }) => {
  const truncatedContent = content?.length > 200 ? content.substring(0, 200) + '...' : content;
  return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
};

interface ForumCategoryPageProps {
  category: string;
}

const ForumCategoryPage: React.FC<ForumCategoryPageProps> = ({ category }) => {
  const [allPosts, setAllPosts] = useState<Post[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
  const [topTags, setTopTags] = useState<TagFrequency[]>([]);
  const [searchTag, setSearchTag] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchPosts = async () => {
    setLoading(true); // Set loading to true before fetching posts

    const postsRef = collection(db, 'posts');
    let postsQuery;

    if (category.toLowerCase() === 'analysis') {
      postsQuery = query(postsRef, where('category', '==', 'analysis'), orderBy('createdAt', 'desc'));
    } else if (category.toLowerCase() === 'insights') {
      postsQuery = query(postsRef, where('category', '==', 'insights'), orderBy('createdAt', 'desc'));
    } else {
      postsQuery = query(postsRef, orderBy('createdAt', 'desc'));
    }

    const postsSnapshot = await getDocs(postsQuery);
    const postData = postsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data() as DocumentData,
    })) as Post[];

    setAllPosts(postData);
    setFilteredPosts(postData);
    setLoading(false); // Set loading to false once posts are fetched
  };

  const fetchTags = async () => {
    const postsSnapshot = await getDocs(collection(db, 'posts'));
    const allPosts = postsSnapshot.docs.map((doc) => doc.data() as Post);

    const tagMap: Record<string, number> = {};
    allPosts.forEach((post) => {
      if (post && post.tags) {
        post.tags.forEach((tag) => {
          tagMap[tag] = (tagMap[tag] || 0) + 1;
        });
      }
    });

    const sortedTags = Object.entries(tagMap)
      .map(([tag, count]) => ({ tag, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    setTopTags(sortedTags);
  };

  useEffect(() => {
    fetchPosts();
    fetchTags();
  }, [category]);

  const handleSearch = (searchValue: string) => {
    if (!searchValue) {
      setFilteredPosts(allPosts);
    } else {
      const lowercasedSearchValue = searchValue.toLowerCase();

      const filtered = allPosts.filter((post) =>
        post.tags.some((tag) => tag.toLowerCase().includes(lowercasedSearchValue)) ||
        post.title.toLowerCase().includes(lowercasedSearchValue) ||
        post.content.toLowerCase().includes(lowercasedSearchValue)
      );

      setFilteredPosts(filtered);
    }

    setSearchTag(searchValue);
  };

  return (
    <Container sx={{ mt: 4 }}>
      {/* Display Top 10 Tags */}
      <Box sx={{ display: 'flex', mb: 4, flexWrap: 'wrap', justifyContent: 'center' }}>
        {topTags.map((tagData) => (
          <Chip
            key={tagData.tag}
            label={`#${tagData.tag} (${tagData.count})`}
            onClick={() => handleSearch(tagData.tag)}
            sx={{ marginRight: 1, marginBottom: 1, cursor: 'pointer' }}
            variant={searchTag === tagData.tag ? 'filled' : 'outlined'}
          />
        ))}
      </Box>

      {/* Search and Create Post buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <TextField
          label="Search by Tag, Title, or Description"
          value={searchTag}
          onChange={(e) => handleSearch(e.target.value)}
          variant="outlined"
          sx={{
            mr: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: 'white' },
              '&.Mui-focused fieldset': { borderColor: 'white' },
            },
            '& .MuiInputLabel-root': { color: 'white' },
            '& .MuiInputBase-input': { color: 'white' },
          }}
        />
        <Button variant="contained" onClick={() => handleSearch(searchTag)}>
          Search
        </Button>
        <Button variant="outlined" component={Link} to="/forum/create-post" sx={{ ml: 6 }}>
          Create a Post
        </Button>
      </Box>

      {/* Loader or Posts */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        filteredPosts.length > 0 ? (
          <Grid container spacing={2}>
            {filteredPosts.map((post) => (
              <Grid item md={6} xs={12} key={post.id}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 2,
                  }}
                  onClick={() => navigate(`/post/${post.id}`)}
                >
                  <CardActionArea sx={{ flexGrow: 1 }} className="post-content">
                    <CardContent>
                      {post.images && post.images.length > 0 && (
                        <Box
                          component="img"
                          src={post.images[0]}
                          alt="Post image"
                          sx={{
                            width: '100%',
                            height: 200,
                            objectFit: 'cover',
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          }}
                        />
                      )}
                      <Typography gutterBottom variant="h5" component="div">
                        {post.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }} className="post-content">
                        <TruncatedContent content={post.content} />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="secondary">
                      View More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
            No posts available.
          </Typography>
        )
      )}
    </Container>
  );
};

export default ForumCategoryPage;
