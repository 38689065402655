import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Toolbar,
  TablePagination,
  Box,
  Typography,
  Container,
} from '@mui/material';
import StockDrawer from './drawer';
import Logo from './logo'; // If you have a logo component; otherwise, remove
import '../style/MarketTable.css';

type StockAsset = {
  symbol: string;
  name: string;
  sector: string;
  industry: string;
  last: number;
  change: number;
  changepct: number;
  volume: number;
};

function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const getCachedData = (key: string) => {
  const cachedData = localStorage.getItem(key);
  if (!cachedData) return null;

  const { data, expiry } = JSON.parse(cachedData);
  if (Date.now() > expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return data;
};

const setCache = (key: string, data: any, ttl = 300000) => {
  const expiry = Date.now() + ttl;
  localStorage.setItem(key, JSON.stringify({ data, expiry }));
};

type SortConfig = {
  column: keyof StockAsset | null;
  direction: 'asc' | 'desc';
};

type StocksTableProps = {
  defaultRowsPerPage?: number; // Optional prop for setting default rows per page
};

const StocksTable: React.FC<StocksTableProps> = ({ defaultRowsPerPage = 20 }) => {
  const [initialData, setInitialData] = useState<StockAsset[]>([]);
  const [filteredData, setFilteredData] = useState<StockAsset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage); // Initialize with prop value
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selectedSymbol, setSelectedSymbol] = useState<StockAsset | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ column: 'volume', direction: 'desc' });

  useEffect(() => {
    const fetchData = async () => {
      const cachedData = getCachedData('stockData');

      if (cachedData) {
        setInitialData(cachedData);
        setFilteredData(cachedData);
        setLoading(false);
      } else {
        try {
          setLoading(true);
          const baseUrl = process.env.REACT_APP_API_BASE_URL;
          const response = await axios.get(`${baseUrl}/stocks-list-us-all`);

          const transformedData: StockAsset[] = response.data.map((item: any) => ({
            symbol: item.symbol,
            name: item.name,
            sector: item.sector,
            industry: item.industry,
            last: item.last,
            change: item.change,
            changepct: item.changepct,
            volume: item.volume,
          }));

          setInitialData(transformedData);
          setFilteredData(transformedData);
          setCache('stockData', transformedData);
        } catch (err) {
          setError('Error fetching stock data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const handleOpenDrawer = (item: StockAsset) => {
    setSelectedSymbol(item);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedSymbol(null);
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column: keyof StockAsset) => {
    const direction = sortConfig.column === column && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ column, direction });
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortConfig.column) return 0;
    const valA = a[sortConfig.column];
    const valB = b[sortConfig.column];

    if (valA == null || valB == null) {
      return valA == null ? 1 : -1; // Push null/undefined values to the end
    }

    if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1;
    if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const searchResults = debouncedSearchTerm
    ? sortedData.filter((item) =>
        ['symbol', 'name', 'sector', 'industry'].some((key) =>
          item[key as keyof StockAsset]?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        )
      )
    : sortedData;

  const paginatedData = searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortIndicator = (column: keyof StockAsset) => {
    if (sortConfig.column === column) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <Container>
      <Box>
        <Typography variant="h2" component="h2" pb={2} pt={8}>
          Stock Updates
        </Typography>
        <TableContainer component={Paper} className="market-table">
          <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TextField
              label="Search by Symbol, Name, Sector, or Industry"
              value={searchTerm}
              onChange={handleSearchTermChange}
              variant="outlined"
              size="small"
            />
          </Toolbar>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '120px' }}>Logo</TableCell>
                  <TableCell onClick={() => handleSort('symbol')}>
                    Symbol {sortIndicator('symbol')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('name')}>
                    Name {sortIndicator('name')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('last')}>
                    Price {sortIndicator('last')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('change')}>
                    Change {sortIndicator('change')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('changepct')}>
                    % Change {sortIndicator('changepct')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('volume')}>
                    Volume {sortIndicator('volume')}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((item) => (
                  <TableRow key={item.symbol}>
                    <TableCell sx={{ width: '120px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Logo symbol={item.symbol} market_type="stock" />
                      </Box>
                    </TableCell>
                    <TableCell>{item.symbol}</TableCell>
                    <TableCell>
                      {item.name.length > 20 ? `${item.name.substring(0, 20)}...` : item.name}
                    </TableCell>
                    <TableCell>
                      ${item.last !== null && item.last !== undefined ? item.last.toFixed(2) : 'N/A'}
                    </TableCell>
                    <TableCell
                      className={item.change > 0 ? 'percent-change-positive' : 'percent-change-negative'}
                    >
                      ${item.change !== null && item.change !== undefined ? item.change.toFixed(2) : 'N/A'}
                    </TableCell>
                    <TableCell
                      className={item.changepct > 0 ? 'percent-change-positive' : 'percent-change-negative'}
                    >
                      {item.changepct !== null && item.changepct !== undefined
                        ? (item.changepct * 100).toFixed(2)
                        : 'N/A'}
                      %
                    </TableCell>
                    <TableCell>
                      {item.volume !== null && item.volume !== undefined ? item.volume.toLocaleString() : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handleOpenDrawer(item)}>
                        View More
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={searchResults.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          />
        </TableContainer>
        <StockDrawer open={drawerOpen} onClose={handleCloseDrawer} symbol={selectedSymbol?.symbol} />
      </Box>
    </Container>
  );
};

export default StocksTable;
