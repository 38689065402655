import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Modal,
  Box,
  IconButton,
  CircularProgress,
  Grid2,
  Container,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { IndividualStockCryptoQuote } from "../types/marketTable";
import Logo from "./logo";
import {
  favoriteAsset,
  unfavoriteAsset,
  isFavorite,
} from "../hooks/favoriteAsset";
import { Star, StarBorder } from "@mui/icons-material";
import ArticleItem from "./ArticleItem"; // Import the ArticleItem component
import { Article } from "../types/news"; // Assuming this is the article type
import { auth } from "../firebaseConfig";
import type { cryptoAsset } from "../types/crypto";
import { useDispatch } from 'react-redux';
import { toggleReload } from '../store/topStockSlice';

const StockDrawer = ({ open, onClose, symbol, cryptoID }) => {
    const dispatch = useDispatch();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [stockData, setStockData] = useState<
    IndividualStockCryptoQuote | cryptoAsset | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isFavorited, setIsFavorited] = useState(false);
  const [newsArticles, setNewsArticles] = useState<Article[]>([]); // State for news articles
  const navigate = useNavigate();

  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const handleDrawerClose = () => {
    setStockData(null); // Reset stock data when the drawer is closed
    onClose(); // Call the onClose prop passed to the component
  };

  

  const fetchStockData = async () => {
    if (!symbol) return;
    setLoading(true);
    setError(null);

    // console.log("Fetching stock data for:", symbol, cryptoID);

    try {
      if (!cryptoID) {
        // Fetch stock data
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get<IndividualStockCryptoQuote>(
          `${baseUrl}/stock-quote?symbol=${symbol}`
        );
        setStockData(response.data[0]);
      } else {
        // Fetch crypto data
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get<cryptoAsset>(
          `${baseUrl}/crypto-quote-and-details?id=${cryptoID}`
        );
        setStockData(response.data);
      }
    } catch (err) {
      setError("Failed to fetch asset data");
    } finally {
      setLoading(false);
    }
  };

  const handleFavoriteToggle = async () => {
    const user = auth.currentUser;

    if (!user) {
      setOpenLoginModal(true); // Show modal if user isn't authenticated
      return;
    }

    if (stockData) {
      if (isFavorited) {
        await unfavoriteAsset(symbol, cryptoID);
        dispatch(toggleReload());
      } else {
        if (cryptoID) {
            await favoriteAsset(symbol, cryptoID);
            dispatch(toggleReload());
        } else{
            await favoriteAsset(symbol, cryptoID);
            dispatch(toggleReload());

        }
      }
      setIsFavorited(!isFavorited);
    }
  };

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      const favorited = await isFavorite(symbol, cryptoID);
      setIsFavorited(favorited);
    };

    if (open && symbol) {
      fetchStockData();
      checkFavoriteStatus();
    }
  }, [open, symbol]);

  return (
    <Container sx={{ justifyContent: "center", alignItems: "center" }}>
     <Drawer
  anchor="right"
  open={open}
  onClose={handleDrawerClose}
  PaperProps={{
    sx: {
      width: {
        xs: "90%",  // Slight margin on the sides for better mobile view
        sm: "90%",  // Keep it consistent for small screens
        md: "500px", // Larger screens can use a fixed width
      },
      padding: "16px",
      backgroundColor: "#4a256f",
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }}
>
        <IconButton
          onClick={handleDrawerClose}
          sx={{ alignSelf: "flex-end", color: "white" }}
        >
          <CloseIcon />
        </IconButton>

        {symbol && (
          <>
            <Box>
              <Box display="flex" alignItems="center" mb={2}>
              <Box sx={{ width: "50px", height: "50px" }}>
                <Logo
                    symbol={symbol}
                    market_type={cryptoID ? "crypto" : "stock"}
                    logoUrl={cryptoID ? (stockData as cryptoAsset)?.logo : undefined}
                    cryptoID={cryptoID ? cryptoID : undefined}
                />
                </Box>

                <Box display="flex" flexDirection="column" ml={2}>
                  <Typography variant="h5">{symbol.toUpperCase()}</Typography>
                  <Typography variant="h6" noWrap>{stockData?.name}</Typography>
                  <Box sx={{ paddingLeft: "0px !important" }}>
                    <IconButton
                      color="secondary"
                      onClick={handleFavoriteToggle}
                      sx={{ display: "flex", alignItems: "left", mt: 2 }}
                    >
                      {isFavorited ? (
                        <Star sx={{ color: "yellow" }} />
                      ) : (
                        <StarBorder sx={{ color: "white" }} />
                      )}
                      <Typography sx={{ ml: 1, color: "white" }}>
                        Favorite
                      </Typography>
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              {/* Display stock or crypto data */}
              {loading ? (
                <CircularProgress color="inherit" />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : stockData ? (
                <>
                  {/* Crypto-specific data */}
                  {cryptoID ? (
                    <>
                      {/* crypto-specific data */}
                      <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Name</Typography>
                          <Typography>
                            {(stockData as cryptoAsset)?.name}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Symbol</Typography>
                          <Typography>
                            {(stockData as cryptoAsset)?.symbol}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 12, lg: 12 }} sx={{ mb: 2, width: '100%' }}>
  <Typography variant="h6">Description</Typography>
  <Typography variant="body1" sx={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>
    {(stockData as cryptoAsset)?.description}
  </Typography>
</Grid2>

                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Price</Typography>
                          <Typography>
                            $
                            {(
                              stockData as cryptoAsset
                            )?.quote?.USD?.price?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Market Cap</Typography>
                          <Typography>
                            $
                            {(
                              stockData as cryptoAsset
                            )?.quote?.USD?.market_cap?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Volume (24h)</Typography>
                          <Typography>
                            $
                            {(
                              stockData as cryptoAsset
                            )?.quote?.USD?.volume_24h?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Change (1h)</Typography>
                          <Typography>
                            {(
                              stockData as cryptoAsset
                            )?.quote?.USD?.percent_change_1h?.toFixed(2)}
                            %
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Change (24h)</Typography>
                          <Typography>
                            {(
                              stockData as cryptoAsset
                            )?.quote?.USD?.percent_change_24h?.toFixed(2)}
                            %
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Circulating Supply</Typography>
                          <Typography>
                            {(
                              stockData as cryptoAsset
                            )?.circulating_supply?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Total Supply</Typography>
                          <Typography>
                            {(
                              stockData as cryptoAsset
                            )?.total_supply?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        {stockData?.max_supply && (
                          <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                            <Typography>Max Supply</Typography>
                            <Typography>
                              {(
                                stockData as cryptoAsset
                              )?.max_supply?.toLocaleString()}
                            </Typography>
                          </Grid2>
                        )}
                        {/* Rank */}
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>CMC Rank</Typography>
                          <Typography>
                            {(stockData as cryptoAsset)?.cmc_rank}
                          </Typography>
                        </Grid2>

                        {/* Date Added */}
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Date Added</Typography>
                          <Typography>
                            {(stockData as cryptoAsset)?.date_added
                              ? new Date(
                                  (stockData as cryptoAsset)
                                    ?.date_added as string
                                ).toLocaleDateString()
                              : "N/A"}
                          </Typography>
                        </Grid2>

                        {/* Website */}
                        {(stockData as cryptoAsset)?.urls?.website && (
                          <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                            <Typography>Website</Typography>
                            <Typography>
                              <a
                                href={
                                  (stockData as cryptoAsset)?.urls?.website?.[0]
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {(stockData as cryptoAsset)?.urls
                                  ?.website?.[0] ?? "N/A"}
                              </a>
                            </Typography>
                          </Grid2>
                        )}

                        {/* Add more crypto-specific data as needed */}
                      </Grid2>
                    </>
                  ) : (
                    <>
                      {/* Stock-specific data */}
                      <Typography variant="h3" mt={2} mb={2}>
                        Stock Information:
                      </Typography>
                      <Grid2 container spacing={1}>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Open Price</Typography>
                          <Typography>
                            $
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.open?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>High Price</Typography>
                          <Typography>
                            $
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.dayHigh?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Percentage Change</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.changesPercentage?.toFixed(2)}
                            %
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Low Price</Typography>
                          <Typography>
                            $
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.dayLow?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Previous Close</Typography>
                          <Typography>
                            $
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.previousClose?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Volume</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.volume?.toLocaleString()}
                          </Typography>
                        </Grid2>
                      </Grid2>

                      {/* Market Cap, PE, EPS */}
                      <Box mt={1} display="flex" gap={2} sx={{ flexWrap: 'wrap' }}>
                        <Button
                          variant="outlined"
                          color="white"
                          sx={{ minWidth: 100 }}
                        >
                          Market Cap: &nbsp;<br />{"  "}
                          <Typography>
                            $
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.marketCap?.toLocaleString()}
                          </Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          color="white"
                          sx={{ minWidth: 100 }}
                        >
                          PE:  &nbsp;<br />{" "}
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.pe?.toLocaleString()}
                          </Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          color="white"
                          sx={{ minWidth: 100 }}
                        >
                          EPS: &nbsp; <br />{" "}
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.eps?.toLocaleString()}
                          </Typography>
                        </Button>
                      </Box>

                      {/* Additional Metrics */}
                      <Typography variant="h3" mt={4} mb={2}>
                        Additional Metrics:
                      </Typography>
                      <Grid2 container spacing={1}>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Number of Transactions</Typography>
                          <Typography>23</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>VWAP</Typography>
                          <Typography>485.6609</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Market Information</Typography>
                          <Typography>Binance</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Exchange Symbol</Typography>
                          <Typography>
                            {(stockData as IndividualStockCryptoQuote)?.symbol}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Average Volume</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.avgVolume?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Price Change</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.change?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>50-Day Average Price</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.priceAvg50?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>200-Day Average Price</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.priceAvg200?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Shares Outstanding</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.sharesOutstanding?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Year High</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.yearHigh?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Year Low</Typography>
                          <Typography>
                            {(
                              stockData as IndividualStockCryptoQuote
                            )?.yearLow?.toLocaleString()}
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Earnings Announcement</Typography>
                          <Typography>
                            {
                              (stockData as IndividualStockCryptoQuote)
                                ?.earningsAnnouncement
                            }
                          </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6, lg: 6 }} sx={{ mb: 2 }}>
                          <Typography>Exchange</Typography>
                          <Typography>
                            {
                              (stockData as IndividualStockCryptoQuote)
                                ?.exchange
                            }
                          </Typography>
                        </Grid2>
                      </Grid2>
                    </>
                  )}
                </>
              ) : (
                <Typography variant="body1" mt={1}>
                  No data available.
                </Typography>
              )}
            </Box>
          </>
        )}
      </Drawer>
{/* todo: add news */}
      <Modal
        open={openLoginModal}
        onClose={handleCloseLoginModal}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="login-modal-title" variant="h6" component="h2">
            Please Log In
          </Typography>
          <Typography id="login-modal-description" sx={{ mt: 2 }}>
            You need to log in to favorite assets and enjoy a personalized
            experience.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => {
              navigate("/login"); // Redirect user to login page
              handleCloseLoginModal(); // Close modal
            }}
          >
            Log In
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default StockDrawer;
