import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container, Grid2, Divider } from '@mui/material';
import { auth } from '../firebaseConfig';
import { updatePassword, deleteUser, EmailAuthProvider, reauthenticateWithCredential, signOut } from 'firebase/auth';
import TopStocks from './topStocks'; // Assuming you have a component for TopStocks
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deleteError, setDeleteError] = useState('');

  const user = auth.currentUser;
  const navigate = useNavigate();


  // Handle password change logic
  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      if (user) {
        await updatePassword(user, newPassword);
        setSuccessMessage('Password updated successfully');
        setErrorMessage(''); // Clear any previous error messages
        setNewPassword('');
        setConfirmPassword('');
      }
    } catch (error) {
      setErrorMessage('Failed to update password');
      setSuccessMessage(''); // Clear success message in case of error
    }
  };

  // Handle account deletion
  // Handle account deletion
  const handleDeleteAccount = async () => {
    try {
      if (user && user.email) {  // Ensure user and user.email are not null
        // Prompt the user for their password
        const password = prompt("Enter your password to delete account");

        // Check if the password is not null
        if (!password) {
          setDeleteError('Password entry is required to delete the account.');
          return;
        }

        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);

        // Proceed with account deletion
        await deleteUser(user);
        setDeleteError('');

        // Perform sign-out and redirect
        await signOut(auth);  // Sign out the user from Firebase
        navigate('/login');   // Redirect to the login or home page
      } else {
        setDeleteError('Unable to delete account: user email is not available. Your entered password may be wrong');
      }
    } catch (error) {
      setDeleteError('Failed to delete account. Please log in again or reauthenticate. Your password entered may be wrong');
    }
  };
  

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>

      {/* Basic Info Section */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Account Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          Email: {user ? user.email : 'Not available'}
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>

      {/* Top Stocks Section */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Your Top Stocks
        </Typography>
        <TopStocks profilePage={true} />
        <Divider sx={{ my: 2 }} />
      </Box>

      {/* Change Password Section */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Change Password
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth: '400px' }}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          {successMessage && (
            <Typography color="primary">{successMessage}</Typography>
          )}
          <Button variant="contained" color="primary" onClick={handlePasswordChange}>
            Change Password
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>

      {/* Delete Account Section */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom color="error">
          Delete Account
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Warning: Deleting your account is irreversible. All your data will be lost.
        </Typography>
        {deleteError && (
          <Typography color="error" gutterBottom>
            {deleteError}
          </Typography>
        )}
        <Button
          variant="outlined"
          color="error"
          onClick={handleDeleteAccount}
          sx={{ mt: 2 }}
        >
          Delete Account
        </Button>
      </Box>
    </Container>
  );
};

export default ProfilePage;
