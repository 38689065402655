import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Define the type for each item in the most actives list
interface MostActive {
  symbol: string;
  volume: number;
  trade_count: number;
//   last_updated: string;
}

// Define the state type for most actives
interface MostActivesState {
  mostActives: MostActive[]; // Array of MostActive objects
  loading: boolean;
  error: string | null;
}

// Initial state with default values
const initialState: MostActivesState = {
  mostActives: [],
  loading: false,
  error: null,
};

// Async thunk for fetching most active stocks
export const fetchMostActives = createAsyncThunk('market/fetchMostActives', async () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL; // Use the base URL from env variables
  const response = await fetch(`${baseUrl}/api/most-actives`);
  if (!response.ok) {
    throw new Error('Failed to fetch most active stocks');
  }
  const data = await response.json();
  return data.mostActives;
});

// Create the slice for most actives
const mostActivesSlice = createSlice({
  name: 'mostActives',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMostActives.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMostActives.fulfilled, (state, action: PayloadAction<MostActive[]>) => {
        state.mostActives = action.payload; // Update the most actives data
        state.loading = false;
      })
      .addCase(fetchMostActives.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error fetching most active stocks';
      });
  },
});

// Export the reducer to be used in the store
export default mostActivesSlice.reducer;
